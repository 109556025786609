import { dynamicRoutes } from '@/router/routes'

function filterRoutes (routes, role) {
  return routes.filter(route => {
    if (role !== 'admin') {
      if (route.meta.role === 'admin') {
        return false
      } else {
        if (route.children) route.children = filterRoutes(route.children, role)
      }
    }
    return true
  })
}

export default {
  state: {
    routes: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.routes = routes
    }
  },
  actions: {
    GenerateRoutes ({ commit }, role) {
      return new Promise(resolve => {
        commit('SET_ROUTES', filterRoutes(dynamicRoutes, role))
        resolve()
      })
    }
  }
}
