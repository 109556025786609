<template>
  <a-dropdown>
    <div style="margin-right: 24px;">
      <a-avatar icon="user" style="margin-right: 8px;"></a-avatar>
      <span style="cursor: default; user-select: none;">{{ nickname }}</span>
    </div>
    <a-menu slot="overlay">
      <a-menu-item>
        <a @click="handleClick">
          <a-icon type="logout" style="margin-right: 8px;"></a-icon>
          <span>退出登录</span>
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DropdownMenu',
  methods: {
    ...mapActions(['Logout']),
    handleClick () {
      this.$confirm({
        centered: true,
        content: '要注销登录吗？',
        title: '提示',
        onOk: () => { this.Logout().then(() => { window.location.reload() }) }
      })
    }
  },
  computed: {
    ...mapGetters(['nickname'])
  }
}
</script>
