<template>
  <a-layout style="min-height: 100vh;">
    <a-layout-sider
      breakpoint="md"
      :collapsed="collapsed"
      :collapsed-width="0"
      :collapsible="true"
      :trigger="null"
      @breakpoint="broken => { this.collapsed = broken }"
    >
      <router-link :to="{ name: 'Index' }">
        <div style="text-align: center;">
          <img v-show="!collapsed" alt="logo" :src="require('@/assets/images/logo.svg')" style="height: 64px; width: 172px;" />
        </div>
      </router-link>
      <a-menu
        mode="inline"
        :open-keys.sync="openKeys"
        :selected-keys="selectedKeys"
        theme="dark"
        @select="({ item, key, selectedKeys }) => $router.push({ name: key })"
      >
        <template v-for="route in routes">
          <template v-if="route.children">
            <a-sub-menu :key="route.name">
              <span slot="title">
                <a-icon :type="route.meta.icon"></a-icon>
                <span>{{ route.meta.title }}</span>
              </span>
              <template v-for="child in route.children">
                <a-menu-item v-if="child.meta.hidden !== true" :key="child.name">{{ child.meta.title }}</a-menu-item>
              </template>
            </a-sub-menu>
          </template>
          <template v-else>
            <a-menu-item v-if="route.meta.hidden !== true" :key="route.name">
              <a-icon :type="route.meta.icon"></a-icon>
              <span>{{ route.meta.title }}</span>
            </a-menu-item>
          </template>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #ffffff; padding: 0;">
        <a-icon
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => { this.collapsed = !this.collapsed }"
          style="font-size: 16px; margin-left: 24px;"
        >
        </a-icon>
        <dropdown-menu style="float: right;"></dropdown-menu>
      </a-layout-header>
      <breadcrumb style="margin-top: 16px; margin-left: 16px; margin-right: 16px;"></breadcrumb>
      <a-layout-content style="margin: 16px; min-height: 480px;">
        <router-view></router-view>
      </a-layout-content>
      <a-layout-footer>
        <b-footer></b-footer>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import BFooter from '@/components/BFooter'
import Breadcrumb from './modules/Breadcrumb'
import DropdownMenu from './modules/DropdownMenu'

export default {
  name: 'MainLayout',
  components: { BFooter, Breadcrumb, DropdownMenu },
  data () {
    return {
      openKeys: [],
      cachedOpenKeys: [],
      selectedKeys: [],
      collapsed: false
    }
  },
  mounted () {
    this.updateMenu()
  },
  methods: {
    updateMenu () {
      const routes = this.$route.matched.concat()
      const openKeys = []
      routes.forEach(item => openKeys.push(item.name))
      if (this.collapsed) {
        this.cachedOpenKeys = openKeys
      } else {
        this.openKeys = openKeys
      }
      this.selectedKeys = [routes.pop().name]
    }
  },
  computed: {
    ...mapGetters(['routes'])
  },
  watch: {
    collapsed (val) {
      if (val) {
        this.cachedOpenKeys = this.openKeys
        this.openKeys = []
      } else {
        this.openKeys = this.cachedOpenKeys
      }
    },
    $route () {
      this.updateMenu()
    }
  }
}
</script>
