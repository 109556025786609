import Vue from 'vue'
import VueRouter from 'vue-router'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import storage from 'store'

import MainLayout from '@/layouts/MainLayout'
import { constantRoutes } from '@/router/routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantRoutes
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (storage.get('ACCESS_TOKEN')) {
    if (store.getters.role === '') {
      store.dispatch('GetProfile').then(res => {
        store.dispatch('GenerateRoutes', res.role).then(() => {
          router.addRoute({
            path: '/admin',
            name: 'Admin',
            meta: { title: '首页' },
            redirect: 'admin/dashboard',
            component: MainLayout,
            children: store.getters.routes
          })
          router.addRoute({ path: '*', name: '404', meta: { title: '404' }, component: () => import('@/views/error/404') })
        })
      })
    }
    if (to.name === 'Login' || to.name === 'Register') {
      next({ name: 'Dashboard' })
    } else {
      next()
    }
  } else {
    if (to.name === 'Index' || to.name === 'Login' || to.name === 'Register') next()
    else next({ name: 'Login' })
  }
})

router.afterEach(() => { NProgress.done() })

export default router
