<template>
  <div class="auth-layout">
    <div style="height: 96px; text-align: center;">
      <img alt="logo" :src="require('@/assets/images/logo.svg')" style="height: 100%;" />
    </div>

    <div style="width: 360px; margin: 80px auto 48px auto;">
      <router-view></router-view>
    </div>

    <b-footer style="margin-top: 24px;"></b-footer>
  </div>
</template>

<script>
import BFooter from '@/components/BFooter'

export default {
  name: 'AuthLayout',
  components: { BFooter }
}
</script>

<style lang="less" scoped>
.auth-layout {
  background: url(~@/assets/images/admin_background.png);
  background-size: 100%;
  min-height: 100vh;
  padding-top: 120px;
  position: relative;
}
</style>
