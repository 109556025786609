var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout',{staticStyle:{"min-height":"100vh"}},[_c('a-layout-sider',{attrs:{"breakpoint":"md","collapsed":_vm.collapsed,"collapsed-width":0,"collapsible":true,"trigger":null},on:{"breakpoint":function (broken) { this$1.collapsed = broken }}},[_c('router-link',{attrs:{"to":{ name: 'Index' }}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],staticStyle:{"height":"64px","width":"172px"},attrs:{"alt":"logo","src":require('@/assets/images/logo.svg')}})])]),_c('a-menu',{attrs:{"mode":"inline","open-keys":_vm.openKeys,"selected-keys":_vm.selectedKeys,"theme":"dark"},on:{"update:openKeys":function($event){_vm.openKeys=$event},"update:open-keys":function($event){_vm.openKeys=$event},"select":function (ref) {
	var item = ref.item;
	var key = ref.key;
	var selectedKeys = ref.selectedKeys;

	return _vm.$router.push({ name: key });
}}},[_vm._l((_vm.routes),function(route){return [(route.children)?[_c('a-sub-menu',{key:route.name},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('a-icon',{attrs:{"type":route.meta.icon}}),_c('span',[_vm._v(_vm._s(route.meta.title))])],1),_vm._l((route.children),function(child){return [(child.meta.hidden !== true)?_c('a-menu-item',{key:child.name},[_vm._v(_vm._s(child.meta.title))]):_vm._e()]})],2)]:[(route.meta.hidden !== true)?_c('a-menu-item',{key:route.name},[_c('a-icon',{attrs:{"type":route.meta.icon}}),_c('span',[_vm._v(_vm._s(route.meta.title))])],1):_vm._e()]]})],2)],1),_c('a-layout',[_c('a-layout-header',{staticStyle:{"background":"#ffffff","padding":"0"}},[_c('a-icon',{staticStyle:{"font-size":"16px","margin-left":"24px"},attrs:{"type":_vm.collapsed ? 'menu-unfold' : 'menu-fold'},on:{"click":function () { this$1.collapsed = !this$1.collapsed }}}),_c('dropdown-menu',{staticStyle:{"float":"right"}})],1),_c('breadcrumb',{staticStyle:{"margin-top":"16px","margin-left":"16px","margin-right":"16px"}}),_c('a-layout-content',{staticStyle:{"margin":"16px","min-height":"480px"}},[_c('router-view')],1),_c('a-layout-footer',[_c('b-footer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }