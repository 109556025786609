import message from 'ant-design-vue/es/message'

import axios from 'axios'
import storage from 'store'

import store from '@/store'

const request = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:5000/api',
  timeout: 6000
})

const errorHandler = (error) => {
  const response = error.response
  if (response) {
    message.error(response.data.message)
    if (response.status === 401) {
      const accessToken = storage.get('ACCESS_TOKEN')
      if (accessToken) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => { window.location.reload() }, 1000)
        })
      }
    }
  }
  return Promise.reject(error)
}

request.interceptors.request.use((value) => {
  const accessToken = storage.get('ACCESS_TOKEN')
  if (accessToken) {
    value.headers.Authorization = `Bearer ${accessToken}`
  }
  return value
}, errorHandler)

request.interceptors.response.use((value) => {
  return value.data
}, errorHandler)

export default request
