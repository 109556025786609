import storage from 'store'

import { login } from '@/api/auth'
import { getProfile } from '@/api/user'

export default {
  state: {
    accessToken: '',
    nickname: '',
    role: '',
    profile: {}
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, accessToken) => { state.accessToken = accessToken },
    SET_NICKNAME: (state, nickname) => { state.nickname = nickname },
    SET_ROLE: (state, role) => { state.role = role },
    SET_PROFILE: (state, profile) => { state.profile = profile }
  },
  actions: {
    Login ({ commit }, data) {
      return new Promise((resolve, reject) => {
        login(data).then(res => {
          commit('SET_ACCESS_TOKEN', res.access_token)
          storage.set('ACCESS_TOKEN', res.access_token, { expires: 7 * 24 * 60 * 60 * 1000 })
          resolve()
        }).catch(error => reject(error))
      })
    },
    GetProfile ({ commit }) {
      return new Promise((resolve, reject) => {
        getProfile().then(res => {
          commit('SET_NICKNAME', res.nickname)
          commit('SET_ROLE', res.role)
          commit('SET_PROFILE', res)
          resolve(res)
        }).catch(error => reject(error))
      })
    },
    Logout ({ commit }) {
      return new Promise(resolve => {
        commit('SET_ACCESS_TOKEN', '')
        storage.remove('ACCESS_TOKEN')
        commit('SET_NICKNAME', '')
        commit('SET_ROLE', '')
        commit('SET_PROFILE', {})
        resolve()
      })
    }
  }
}
