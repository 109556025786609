import request from '@/utils/request'

export function login (data) {
  return request.post('/auth/login', data)
}

export function sendLoginVerificationCode (data) {
  return request.post('/auth/login/verification-code', data)
}

export function register (data) {
  return request.post('/auth/register', data)
}

export function sendRegisterVerificationCode (data) {
  return request.post('/auth/register/verification-code', data)
}
