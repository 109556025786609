import AuthLayout from '@/layouts/AuthLayout'
import BlankLayout from '@/layouts/BlankLayout'

export const constantRoutes = [
  { path: '/', name: 'Index', component: () => import('@/views/Index') },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthLayout,
    redirect: 'auth/login',
    children: [
      { path: 'login', name: 'Login', component: () => import('@/views/auth/Login') },
      { path: 'register', name: 'Register', component: () => import('@/views/auth/Register') }
    ]
  }
]

export const dynamicRoutes = [
  { path: 'dashboard', name: 'Dashboard', meta: { icon: 'dashboard', title: '仪表盘' }, component: () => import('@/views/Dashboard') },
  {
    path: 'site',
    name: 'Site',
    meta: { icon: 'compass', title: '站点管理' },
    component: BlankLayout,
    redirect: 'site/site',
    children: [
      { path: 'site', name: 'SiteList', meta: { title: '站点' }, component: () => import('@/views/site/SiteList') },
      { path: 'site/:id/detail', name: 'SiteDetail', meta: { hidden: true, title: '站点详情' }, component: () => import('@/views/site/SiteDetail') },
      { path: 'domain', name: 'DomainList', meta: { title: '域名' }, component: () => import('@/views/site/DomainList') },
      { path: 'domain/:id/detail', name: 'DomainDetail', meta: { hidden: true, title: '域名详情' }, component: () => import('@/views/site/DomainDetail') }
    ]
  },
  {
    path: 'user',
    name: 'User',
    meta: { icon: 'user', role: 'admin', title: '用户管理' },
    component: BlankLayout,
    redirect: 'user/profile',
    children: [
      { path: 'list', name: 'UserList', meta: { title: '用户' }, component: () => import('@/views/user/UserList') }
    ]
  }
]
